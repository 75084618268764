<template>
  <div class="mt-8 mb-5 payAgent-details text-center">
    <p class="text-body-1 grey--text font-weight-regular">
      <strong v-if="this.$store.state.theme === 'payagent'">payAgent</strong><strong v-else>CalmonyPay</strong> is a simple and secure payment platform by
      <strong v-if="this.$store.state.theme === 'payagent'">AgentOS</strong><strong v-else>Calmony.co</strong> powered by <strong>Modulr</strong> and
      <strong>Barclaycard</strong>.
    </p>
    <p class="mt-8 text-body-2 primary--text">Want to know more?</p>
    <v-row justify="center" class="mt-5">
      <v-btn
        width="110"
        color="primary"
        outlined
        class="mx-1"
        @click="rerouteTo('https://www.modulrfinance.com/privacy-policy')"
      >
        <span class="custom-transform-class text-none">Modulr</span>
      </v-btn>
      <v-btn v-if="this.$store.state.them === 'payagent'" @click="rerouteTo('https://payagentapp.co.uk')" width="110" color="primary" depressed class="mx-1">
        <span  class="custom-transform-class text-none">payAgent</span>
      </v-btn>
      <v-btn v-else @click="rerouteTo('https://calmony.co')" width="110" color="primary" depressed class="mx-1">
        <span  class="custom-transform-class text-none">CalmonyPay</span>
      </v-btn>
      
      <v-btn
        width="110"
        color="primary"
        outlined
        class="mx-1"
        @click="rerouteTo('https://www.barclaycard.co.uk/business/terms-of-use')"
      >
        <span class="custom-transform-class text-none">Barclaycard</span>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'appInformation',
  methods: {
    rerouteTo(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style>

</style>