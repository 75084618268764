<template>
  <div></div>
</template>

<script>
export default {
  name: 'checkStorageSupport',
  // Checking if browser supports session storage ELSE set app to use cookies
  // Safari Private mode does not
  created() {
    this.testSessionStorage()
  },
  methods: {
    testSessionStorage() {
      let testKey = 'testKey'
      sessionStorage.setItem('testKey', JSON.stringify(testKey))
      const savedKey = JSON.parse(sessionStorage.getItem('testKey'))

      if (savedKey) console.log('Session support all good!')
      else {
        console.log('Session support NOT supported!');
        alert("Your browser does not support local storage. \nIn order for the application to work correctly please exit Safari Private Mode, or use Google Chrome with browser data enabled.")
      }


      // try {
      //   sessionStorage.removeItem('paymentDetails')
      //   return true
      // }
      // catch (error) {
      //   return false
      // }
    }
  }
}
</script>

<style>
</style>