<template>
  <div>
    <div tabindex="0" class="fade-in text-center mt-3 mt-sm-6 mb-0">
      <p
        v-if="$store.state.paymentDetails.Forename"
        class="my-0 text-h4 text-sm-h3 grey--text text--darken-2"
      >
        Hi {{ $store.state.paymentDetails.Forename }},
      </p>
      <p v-else class="my-0 text-h4 text-sm-h3 grey--text text--darken-2">
        Welcome to <span v-if="this.$store.state.theme === 'payagent'">payAgent</span><span v-else>CalmonyPay</span>
      </p>

      <p class="my-0 text-h6 text-sm-h5 grey--text text--darken-1">
        Let's take care of your payments.
      </p>
      <p
        class="
          font-italic
          text-sm-body-1 text-center
          mt-1
          mb-0
          font-weight-thin
        "
      >
        It`s quick, simple and secure.
      </p>
    </div>

    <div class="fade-in pa-10 pa-sm-3 mt-sm-10 d-flex justify-center">
      <v-img v-if="this.$store.state.theme === 'payagent'"
        :max-width="svgWidth"
        class="svg-image mt-sm-5 mb-0"
        src="../assets/PayAgentBranding/undraw_online_payments.svg"
        contain
      />
      <v-img v-else
        :max-width="svgWidth"
        class="svg-image mt-sm-5 mb-0"
        src="../assets/CalmonyPayBranding/undraw_online_payments.svg"
        contain
      />
    </div>

    <v-btn
      @click="reroute"
      class="mb-3 mt-5 main-button"
      x-large
      depressed
      color="primary"
      block
    >
      <span v-if="$store.state.paymentDetails.IsGenericLink"
        >Make a payment</span
      >
      <span v-else>Go to payment</span>
    </v-btn>

    <div class="fade-in">
      <div
        tabindex="0"
        v-if="$store.state.paymentDetails.IsGenericLink"
        class="text-center"
      >
        <p class="text-center text-h5 my-0">
          {{ $store.state.paymentDetails.Company }}
        </p>
        <p class="text-center text-sm-body-1 my-0 font-weight-light">
          Tel: {{ $store.state.paymentDetails.BranchPhone }}
        </p>
      </div>
      <div v-else tabindex="0" class="text-center mt-3">
        <p
          class="
            text-center text-sm-body-1
            font-weight-regular
            my-0
            font-weight-light
          "
        >
          {{ $store.getters.capitalizeStaffName }}
        </p>
        <p class="text-center text-sm-body-1 mt-1 mb-0 font-weight-light">
          {{ $store.state.paymentDetails.Company }}
        </p>
        <p class="text-center text-sm-body-1 mt-0 mb-3 font-weight-light">
          Tel: {{ $store.state.paymentDetails.BranchPhone }}
        </p>
      </div>
    </div>

    <v-divider class="mt-8 mb-16"></v-divider>

    <AppInformation />

    <CheckStorageSupport />
  </div>
</template>

<script>
import CheckStorageSupport from "@/components/CheckStorageSupport.vue";
import AppInformation from "@/components/AppInformation.vue";

export default {
  name: "PaymentHomePage",
  created() {
    if (this.$store.state.safariBrowser)
      alert(
        "Safari browser detected.\n\nThis app uses session cookies to function properly."
      );
  },
  components: {
    CheckStorageSupport,
    AppInformation,
  },
  computed: {
    svgWidth() {
      if (this.$vuetify.breakpoint.xs) return 250;
      return "60%";
    },
  },
  methods: {
    reroute() {
      if (this.$store.state.isDirectCardPayment) {
        // set payment details and navigate straight to card payment
        let paymentDetails = {
          breakDown: this.$store.state.paymentDetails.Request.Breakdown,
          total: this.$store.state.paymentDetails.Request.TotalLeftToPay,
        };
        this.$store.commit("SET_PAYMENT_DETAILS_TO_SUBMIT", paymentDetails);
        this.$router.push({ name: "PaymentCardConfirm" });
      } else this.$router.push({ name: "PaymentDetails" });
    },
  },
};
</script>

<style scoped>
.svg-image {
  opacity: 0.9;
}

a {
  text-decoration: none;
}

.main-button {
  -webkit-animation: bounce-in-bottom 1.1s 1s both;
  animation: bounce-in-bottom 1.1s 1s both;
}

.fade-in {
  -webkit-animation: slide-down 1s both;
  animation: slide-down 1s both;
}

@keyframes slide-down {
  0% {
    /* transform: translateY(-500px); */
    opacity: 0;
  }
  100% {
    /* transform: translateY(0px); */
    opacity: 1;
  }
}

@keyframes bounce-in-bottom {
  0% {
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(56px);
    transform: translateY(56px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(28px);
    transform: translateY(28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
</style>
